import React from 'react'

import {
  Checkbox,
  CheckboxGroup,
  FormGroup,
  FormIndent,
  Input,
  List,
  ListItem,
  Paragraph,
  Spacer
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="FormIndent"
    components={[{ component: FormIndent }]}
    status={[{ type: 'accessible', version: '14.3.0' }]}
  >
    <Section>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <>
          <FormGroup>
            <Input label="Tavallinen lomakekenttä" />
          </FormGroup>
          <FormGroup noMargin>
            <FormIndent>
              <Input label="Sisennetty lomakekenttä" />
            </FormIndent>
          </FormGroup>
        </>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div className="form-group mb-0">
          <div className="form-indent">
            <LabelHTMLExample htmlFor="form-indent-html-example-1">
              Lorem ipsum
            </LabelHTMLExample>
            <input
              className="form-control"
              type="text"
              id="form-indent-html-example-1"
            />
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Checkboxin lisäkenttänä">
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup>
          <CheckboxGroup
            label="Tavoittenani on"
            subLabel="Valitse yksi tai useampi"
            onChange={() => {}}
            name="form-indent-checkbox-group-example-1"
            value={['2']}
          >
            <Checkbox value="1">Ensisijaisesti työllistyminen</Checkbox>
            <Checkbox
              id="formindent-checkboxgroup-checkbox-example"
              value="2"
            >
              Opiskelu tai opintoihin hakeminen
            </Checkbox>
            <FormGroup>
              <Spacer
                marginLeft="lg"
                marginTop="md"
              >
                <FormIndent ariaLabelledby="formindent-checkboxgroup-checkbox-example">
                  <Input label="Valintaa tarkentava lisäkenttä" />
                </FormIndent>
              </Spacer>
            </FormGroup>
          </CheckboxGroup>
          <Checkbox value="3">Ammatin vaihtaminen</Checkbox>
        </FormGroup>
      </Playground>
    </Section>
    <Section title="Otsikolla">
      <Paragraph>
        Jos <Code>FormIndent</Code>-komponentille määritellään{' '}
        <Code>label</Code> tai <Code>ariaLabelledby</Code>, kaikki sen sisään
        tulevat lomakekentät näytetään pienemmällä otsikkotyylillä.
      </Paragraph>
      <Paragraph>
        Tällöin muodostuu myös semanttinen ryhmä, jonka nimeksi tulee{' '}
        <Code>label</Code> tai <Code>ariaLabelledby</Code> -propilla määritelty
        teksti.
      </Paragraph>

      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <FormIndent
            help="Help"
            label="Label"
            subLabel="Sub Label"
          >
            <FormGroup>
              <Input label="Lorem ipsum" />
            </FormGroup>
            <FormGroup noMargin>
              <Input label="Dolor sit amet" />
            </FormGroup>
          </FormIndent>
        </FormGroup>
      </Playground>
      <Playground WrapperComponent={FormLayoutWrapper}>
        <FormGroup noMargin>
          <FormIndent
            color="secondary"
            label="Ei julkaista työnantajalle"
          >
            <FormGroup>
              <Input label="Lorem ipsum" />
            </FormGroup>
            <FormGroup noMargin>
              <Input label="Dolor sit amet" />
            </FormGroup>
          </FormIndent>
        </FormGroup>
      </Playground>
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div className="form-group mb-0">
          <LabelHTMLExample
            as="p"
            className="text-secondary mb-sm"
            id="form-indent-html-example-2"
          >
            Ei julkaista työnantajalle
          </LabelHTMLExample>
          <div
            aria-labelledby="form-indent-html-example-2"
            className="form-indent form-indent-secondary"
            role="group"
          >
            <div className="form-group">
              <LabelHTMLExample htmlFor="form-indent-html-example-2-input-1">
                Lorem ipsum
              </LabelHTMLExample>
              <input
                className="form-control"
                type="text"
                id="form-indent-html-example-2-input-1"
              />
            </div>
            <div className="form-group mb-0">
              <LabelHTMLExample htmlFor="form-indent-html-example-2-input-2">
                Dolor sit amet
              </LabelHTMLExample>
              <input
                className="form-control"
                type="text"
                id="form-indent-html-example-2-input-2"
              />
            </div>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Älä käytä FormIndentia">
      <List variant="unordered">
        <ListItem>
          sisäkkäisesti eli niin, että ryhmässä olisi useampi{' '}
          <Code>FormIndent</Code> eri tasoilla
        </ListItem>
        <ListItem>
          <Code>RadioButtonGroup</Code>eissa tai
        </ListItem>
        <ListItem>
          <Code>CheckboxButtonGroup</Code>eissa.
        </ListItem>
      </List>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Huomioithan, että <Code>FormIndent</Code> ilman labelia ei tee
        semanttista ryhmittelyä. <Code>FormIndent</Code> on visuaalinen keino
        ryhmitellä tietoa. Visuaalinen korostus ei sellaisenaan välity
        ruudunlukijalle eikä käyttäjälle käy ilmi, mihin kenttä liittyy. Jotta
        ryhmittely välittyy myös ruudunlukijalle, pitää <Code>FormIndent</Code>{' '}
        labeloida.
      </Paragraph>
      <Paragraph>
        Monessa tilanteessa <Code>FormIndent</Code>in voisi korvata
        väliotsikoinnilla. Dynaamisesti lisättävät kentät eivät myöskään aina
        tarvitse <Code>FormIndent</Code>iä. Esimerkiksi Työnhaun aloitus
        -lomakkeella on käytetty esimerkillisesti väliotsikoita, jotka
        rytmittävät lomakkeen selkeisiin kokonaisuuksiin.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
